<template>
  <div class="affixion-contain">
    <Form :label-width="115" :model="sugarData">
      <Row>
        <Col span="8" offset="8">
          <Form-item label="日期：">
            <Date-picker type="date" :options="options" placeholder="选择日期" @on-change="handleChangeDate"></Date-picker>
          </Form-item>
          <Form-item label="采集时间：">
            <Time-picker confirm type="time" placeholder="选择时间" @on-change="handleChangeTime"></Time-picker>
          </Form-item>
          <Form-item label="空腹：">
            <Row>
              <Col :span="21"><Input v-model="sugarData.empty" placeholder="请输入空腹血糖值"></Input></Col>
              <Col :span="3">&nbsp;&nbsp;mmol/L</Col>
            </Row>
          </Form-item>
          <Form-item label="饭后半小时：">
            <Row>
              <Col :span="21"><Input v-model="sugarData.halfhour" placeholder="请输入饭后半小时血糖值"></Input></Col>
              <Col :span="3">&nbsp;&nbsp;mmol/L</Col>
            </Row>
          </Form-item>
          <Form-item label="饭后一小时：">
            <Row>
              <Col :span="21"><Input v-model="sugarData.onehour" placeholder="请输入饭后一小时血糖值"></Input></Col>
              <Col :span="3">&nbsp;&nbsp;mmol/L</Col>
            </Row>
          </Form-item>
          <Form-item label="饭后两小时：">
            <Row>
              <Col :span="21"><Input v-model="sugarData.twohour" placeholder="请输入饭后两小时血糖值"></Input></Col>
              <Col :span="3">&nbsp;&nbsp;mmol/L</Col>
            </Row>
          </Form-item>
          <Form-item label="饭后三小时：">
            <Row>
              <Col :span="21"><Input v-model="sugarData.three" placeholder="请输入饭后三小时血糖值"></Input></Col>
              <Col :span="3">&nbsp;&nbsp;mmol/L</Col>
            </Row>
          </Form-item>
          <Form-item label="随机血糖：">
            <Row>
              <Col :span="21"><Input v-model="sugarData.random_value" placeholder="请输入随机血糖值"></Input></Col>
              <Col :span="3">&nbsp;&nbsp;mmol/L</Col>
            </Row>
          </Form-item>
        </Col>
      </Row>
      <div class="symbol-box tac">
        <Button type="primary" size="large" @click="handleBack">返回</Button>
        <Button type="success" size="large" @click="handleSave()">保存</Button>
      </div>
    </Form>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			sugarData: {
				test_date: '',
				test_time: '',
				empty: '',
				halfhour: '',
				onehour: '',
				twohour: '',
				three: '',
				random_value: '',
			},
			options: {
				disabledDate(date) {
					return date && date.valueOf() >= Date.now();
				},
			},
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	methods: {
		handleBack() {
			this.$router.go(-1);
		},
		handleSave() {
			if (this.validateFormData()) {
				this.sugarData.member_id = this.memberId;
				if (this.sugarData.halfhour === '' || this.sugarData.halfhour === null) {
					this.sugarData.halfhour = '';
				}
				if (this.sugarData.onehour === '' || this.sugarData.onehour === null) {
					this.sugarData.onehour = '';
				}
				if (this.sugarData.twohour === '' || this.sugarData.twohour === null) {
					this.sugarData.twohour = '';
				}
				if (this.sugarData.three === '' || this.sugarData.three === null) {
					this.sugarData.three = '';
				}
				if (this.sugarData.random_value === '' || this.sugarData.random_value === null) {
					this.sugarData.random_value = '';
				}
				// this.sugarData.halfhour = this.sugarData.halfhour === '' ? 0 : this.sugarData.halfhour
				// this.sugarData.onehour = this.sugarData.onehour === '' ? 0 : this.sugarData.onehour
				// this.sugarData.twohour = this.sugarData.twohour === '' ? 0 : this.sugarData.twohour
				// this.sugarData.three = this.sugarData.three === '' ? 0 : this.sugarData.three
				MemberService.sugarAdd(this.sugarData).then(() => {
					this.handleBack();
				});
			}
		},
		handleChangeDate(value) {
			this.sugarData.test_date = value;
		},
		handleChangeTime(value) {
			this.sugarData.test_time = value;
		},
		isNumber(arg) {
			return typeof parseFloat(arg) === 'number';
		},
		isPositiveNumber(value) {
			return this.isNumber(value) && value >= 0;
		},
		validateFormData() {
			if (!this.sugarData.test_date) {
				this.$Message.warning('请选择日期');
				return false;
			} else if (!this.sugarData.test_time) {
				this.$Message.warning('请选择采集时间');
				return false;
			} else if (
				(this.sugarData.empty ||
					this.sugarData.random_value ||
					this.sugarData.halfhour ||
					this.sugarData.onehour ||
					this.sugarData.twohour ||
					this.sugarData.three) === ''
			) {
				this.$Message.warning('请输入任一项血糖值');
				return false;
			} else if (!this.isPositiveNumber(this.sugarData.empty)) {
				this.$Message.warning('请输入空腹血糖有效值');
				return false;
			} else if (!this.isPositiveNumber(this.sugarData.halfhour)) {
				this.$Message.warning('请输入饭后半小时有效值');
				return false;
			} else if (!this.isPositiveNumber(this.sugarData.onehour)) {
				this.$Message.warning('请输入饭后一小时有效值');
				return false;
			} else if (!this.isPositiveNumber(this.sugarData.twohour)) {
				this.$Message.warning('请输入饭后两小时有效值');
				return false;
			} else if (!this.isPositiveNumber(this.sugarData.three)) {
				this.$Message.warning('请输入饭后三小时有效值');
				return false;
			} else if (!this.isPositiveNumber(this.sugarData.random_value)) {
				this.$Message.warning('请输入随机血糖有效值');
				return false;
			} else {
				return true;
			}
		},
	},
};
</script>
<style lang="css" scoped>
.affixion-contain {
  margin-top: 40px;
}
</style>
